import axios from "axios";
import { getCookie, removeCookie } from "@/utils/operateCookie";

const request = axios.create({
    // baseURL: process.env.VUE_APP_HOST, //本地用
    baseURL: location.origin, // 接口的基础路径
    timeout: 20000, // 请求超时时间
});

// 请求拦截器
request.interceptors.request.use(
    (config) => {
        // 在发送请求之前的操作，例如添加请求头等
        if (getCookie("_token")) {
            config.headers["Authorization"] = "Bearer " + getCookie("_token");
        }
        config.headers["Content-Type"] = "multipart/form-data";
        // 追加统一参数vendor
        const v = window.vendorName.toLowerCase();
        const filed = config.method == "get" ? "params" : "data";
        config[filed] ? (config[filed]["vendor"] = v) : { vendor: v };
        return config;
    },
    (error) => {
        // 处理请求错误
        return Promise.reject(error);
    }
);
let hadAlert = false;
// 响应拦截器
request.interceptors.response.use(
    (response) => {
        // 在接收响应之前的操作，例如处理响应数据等
        return response.data;
    },
    (error) => {
        if (
            error.response &&
            (error.response.data.status == 408 ||
                error.response.data.message.includes("please log in again"))
        ) {
            removeCookie("_token");
            window.location.href = "/login";
            return Promise.reject("Login expiration");
        } else if (error.code === "ECONNABORTED") {
            if (hadAlert) return;
            hadAlert = true;
            alert("Network Error");
            //alet有阻断功能，点击之后再改为false
            hadAlert = false;
            return;
        } else {
            process.env.VUE_APP_CUSTOM_ENV == "production"
                ? console.log("error")
                : console.log("err:", error);
            // 处理响应错误
            return Promise.reject(error);
        }
    }
);

export default request;
